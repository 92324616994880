import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from '../styles/staticPages.js';
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { colors } from "components/Theme/WebTheme.js";


const dashboardRoutes = [];

const useStyles = makeStyles(styles);


export default function TermCondition(props) {
    const { t,i18n } = useTranslation();
    const isRTL = i18n.dir();
  const classes = useStyles();
  const settings = useSelector(state => state.settingsdata.settings);
  const { ...rest } = props;
  return (
    <div style={{margin:'-8px'}}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/banner.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
 
      <div className={classes.container}>
            <br/>
            <h2  style={{textAlign:isRTL === 'rtl'?'right':'left',position: "relative",marginTop: "30px",minHeight: "32px",color:colors.Header,textDecoration: "none",[isRTL === "rtl" ? "marginRight" : "marginRight"]: isRTL ? "30px" : "0px",wordBreak: "break-word"}}>KVKK AYDINLATMA METNİ</h2>
            {/* alt alta yazılmış hali */}
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} >
            KVKK Aydınlatma Metni<br/>
Kişisel verileriniz aşağıda belirtilen yöntem, dayanak, amaç ve koşullar çerçevesinde ilgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar işlenmekte ve korunması için gerekli tüm idari ve teknik tedbirler şirketimizce alınmaktadır.<br/>
Kişisel verileriniz için “veri sorumlusu” Taşırım Kurye ve Lojistik Hizmetleri’ dir.<br/>
Kişisel verileriniz, Şirketimizin Genel Müdürlük, Bölge Müdürlüğü birimleri, şubeleri, acenteleri ve tüm bağlı birimleri, teslim tutanakları, internet sitesi, sosyal medya hesapları, mobil uygulamalar, entegrasyon sistemleri, çağrı merkezi, şirketimizin faaliyetlerinin tamamlayıcısı veya uzantısı niteliğindeki hizmetleri aldığı taraflar, anlaşmalı kuruluşlar, iş ortakları, tedarikçi ve alt veya üst taşıyıcılar vasıtasıyla ve benzeri diğer kanalları aracılığıyla otomatik ya da otomatik olmayan yollarla, yazılı, sözlü ya da elektronik ortamda toplanabilmektedir.<br/>
Kişisel verilerinin alınması konusunda başta şirketimizin faaliyet gösterdiği kargo ve posta sektörünü regüle eden özel Kanunlar olmak üzere çeşitli mevzuat hükümleri bulunmaktadır. 4925 sayılı Karayolu Taşıma Kanunu, Karayolu Taşıma Yönetmeliği, 6475 sayılı Posta Hizmetleri Kanunu ve Bilgi Teknolojileri ve İletişim Kurulu’nun 27/12/2016 tarih ve 2016/DK-YED/517 sayılı kararı ile onaylanan Posta Gönderilerine İlişkin Güvenlik Tedbirlerine Yönelik Usul ve Esasları’nda gönderici, alıcı, kargoyu teslim eden ve alanların kişisel verilerinin işlenmesine dair düzenlemeler mevcuttur.<br/>
Belirtilen mevzuattaki gerekleri yerine getirebilmek ve uyum sağlamak, buna ilaveten, sözleşmelerin gereklerini yerine getirebilmek, ilgili sektörel özel mevzuat uyarınca müşteri şikayeti çözüm mekanizması işletmek, hizmetlerimizle ilgili tazmin vb talep başvurularını yanıtlayabilmek, doğabilecek uyuşmazlıklarda delil olarak kullanmak, adli ve resmi makamların taleplerini yerine getirebilmek,<br/>
Gönderici ve/veya alıcılara ilişkin ad-soyad, TC Kimlik numarası, açık adres, telefon numarası, vergi kimlik numarası,<br/>
Gönderi bilgisi<br/>
Gönderilerin alıcıya (gerçek veya tüzel kişi) teslimi aşamasında, teslim alanın adı-soyadı, imzası, T.C. kimlik numarası<br/>
Yabancı uyruklular için; pasaport numarası, uluslararası geçerliliği olan muadili belgenin numarası veya T.C. yetkili mercilerince verilmiş kimliği tespite yarayan belge numarası<br/>
Talep ve şikayetlerin değerlendirilebilmesi için ad-soyad, kimlik ve iletişim bilgileriniz ile çağrı merkezi aramalarında taraflar arasında çıkabilecek ihtilaflarda delil olmak ve kalite denetimi yapabilmek adına ses kaydı alınmaktadır.<br/>
Hizmetlerimizle ilgili tazmin başvurularında gönderinin tespiti, başvurunun değerlendirilebilmesi ve iletişime geçilebilmesi için başvuran şahsın ad-soyad, T.C./VKN no, iletişim bilgileri, gönderi bilgisi alınmaktadır.<br/>
Taşırım Kurye’nin Merkez, şube ve hizmet noktaları dahil tüm işyerlerinde güvenlik amacıyla kamera kaydı alınabilmekte, yine aynı amaç doğrultusunda ziyaretçi bilgileri kaydedilebilmektedir.<br/>
Mevzuattan kaynaklanan hukuki yükümlülüklerimizin yerine getirilmesi, kargo ve posta hizmetlerinin verilebilmesi, bunlara dair işlemlerin yerine getirilmesi, yürütülmesi, ifası, ispatı ve geliştirilebilmesi, müşterilere raporlama yapılması, akdettiğiniz ve/veya akdettiğimiz sözleşmelerin gereğinin yerine getirilmesi; istatistik, müşteri memnuniyeti çalışmaları, güvenliğin sağlanması, ticari ve iş stratejilerinin geliştirilmesi, verilen hizmetlerin analiz edilmesi, karaparanın aklamasının önlenmesi mevzuatına ve yurtiçi ve uluslararası mevzuata uyum; BTK, MASAK ve diğer otoritelerce öngörülen bilgi saklama, raporlama, bilgilendirme yükümlülüklerine uyulması, izninizin olması halinde bu hizmetlere ve ürünlere yönelik tanıtım, pazarlama ve kampanya faaliyetlerinin yapılması, tarafınıza uygun hizmetler ve ürünler geliştirilmesi ve bunun kesintisiz olarak sürdürebilmesi amacıyla kişisel verileriniz işlenmektedir.<br/>
KVKK gereğince Kanunda sayılan şartlardan birinin varlığı hâlinde, açık rızanız aranmaksızın genel nitelikli kişisel verilerin işlenmesi mümkündür;<br/>
a)Kanunlarda açıkça öngörülmesi,<br/>
b)Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün korunması için zorunlu olması<br/>
c)Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması<br/>
d)Veri sorumlusu sıfatıyla ‘Taşırım Kurye Ve Lojistik Hizmetleri’nin hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması,<br/>
e)İlgili kişinin kendisi tarafından alenileştirilmiş olması,<br/>
f)Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması<br/>
g)İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, Şirketimizin meşru menfaatleri için veri işlenmesinin zorunlu olması.<br/>
Yukarıda belirtilen amaçlar doğrultusunda kişisel verileriniz; kargo/posta gönderisinin taraflarına ve ilgililerine, şirketimizin yurt içi ve yurt dışındaki iştirakleri ve bunların alt kuruluşlarına; çalışanları, şirket görevlileri, hukuk, mali ve vergi danışmanları, denetçileri, şirketimizin faaliyetlerinin tamamlayıcısı veya uzantısı niteliğindeki hizmetleri aldığı, işbirliği yaptığı danışmanlar, tedarikçi, alt taşıyıcılar, acenteler, işletmeciler, ifa yardımcıları, banka ve finans kuruluşları; BTK, MASAK, UBAK, Emniyet Müdürlüğü gibi otoriteler, Bakanlıklar, yargı mercileri, idari merciler gibi yetkili kamu kurum ve kuruluşlara, mevzuat hükümlerinin izin verdiği kişi veya rızanızın bulunduğu kurum ve kuruluşlara kişisel verinin türüne bağlı ve amaçla sınırlı olarak yasal çerçeve içerisinde aktarılabilecektir.<br/>
KVKK’nın 11. maddesi uyarınca kişisel verilerinizle ilgili olarak haklarınız; “kişisel veri işlenip işlenmediğini öğrenme, Kişisel veriler işlenmişse buna ilişkin bilgi talep etme, Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme, Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme, Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme, yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme, KVKK’da öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok edilmesini isteme, yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme, İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme, Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğranması hâlinde zararın giderilmesini talep etme.” şeklindedir.<br/>

<br/><br/><br/>

            </p>
      
          </div>
        </div>
      <Footer/>
    </div>
  );
}
