import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from '../styles/staticPages.js';
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { colors } from "components/Theme/WebTheme.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);


export default function TermCondition(props) {
    const { t,i18n } = useTranslation();
    const isRTL = i18n.dir();
  const classes = useStyles();
  const settings = useSelector(state => state.settingsdata.settings);
  const { ...rest } = props;
  return (
    <div style={{margin:'-8px'}}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/banner.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
 
      <div className={classes.container}>
            <br/>
            <h2  style={{textAlign:isRTL === 'rtl'?'right':'left',position: "relative",marginTop: "30px",minHeight: "32px",color:colors.Header,textDecoration: "none",[isRTL === "rtl" ? "marginRight" : "marginRight"]: isRTL ? "30px" : "0px",wordBreak: "break-word"}}>SATIŞ VE HİZMET POLİTİKASI</h2>
            {/* alt alta yazılmış hali */}
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} >

            TARAFLAR<br/>
SATICI: Taşırım Kurye Ve Lojistik Hizmetleri. Bundan sonra TAŞIRIM KURYE olarak anılacaktır.<br/>
MÜŞTERİ: Siteyi ziyaret eden/kullanan/işlem gerçekleştiren kullanıcı.<br/>

TANIMLAR<br/>
MADDE 2<br/>
Kullanıcı: www.tasirimkurye.com sitesine çevrimiçi ortamdan erişen kayıtlı her gerçek ve tüzel kişidir.<br/>
Kurye: Taşımacılık faaliyetini gerçekleştiren gerçek veya tüzel kişi.<br/>
Platform: Kullanıcılar ve Kurye veya kurye şirketlerinin www.tasirimkurye.com web sitesinden ve taşırım kurye mobil uygulaması üzerinden bir araya geldiği sanal ortamdır.<br/>
Site: www.tasirimkurye.com isimli alan adından ve bu alan adına bağlı alt alan adlarından oluşan internet sitesidir.<br/>

SÖZLEŞMENİN KONUSU VE KAPSAMI<br/>
MADDE 3<br/>
1. İşbu sözleşmenin konusu, KULLANICI’nın, www.tasirimkurye.com sitesinden ve taşırım kurye mobil uygulaması üzerinden alacağı hizmetlerden faydalanma şartları ile ilgili olarak Tüketicinin Korunması Hakkındaki Kanun ve Mesafeli Sözleşmelere Dair Yönetmelik hükümleri gereğince tarafların hak ve yükümlülüklerinin saptanması oluşturur.<br/>
2. Taşırım Kurye, Kurye şirketleri veya taşırım kurye ile bu hizmeti almak isteyen Kullanıcıların bir araya geldiği, taraflara yazılım desteğinin verildiği dijital bir platformdur.<br/>
3. KULLANICI, KURYE VEYA KURYE ŞİRKETİ İLE ARASINDAKİ HİZMET İLİŞKİSİNDE TAŞIRIM KURYE’NİN İŞBU İLİŞKİNİN TARAFI OLMADIĞINI, HİZMET İLİŞKİSİ VE HİZMET KONUSU FAALİYETE İLİŞKİN HERHANGİ TAAHHÜT VE YÜKÜMLÜLÜKTE BULUNMADIĞINI ONAYLAR.<br/>
4. TAŞIRIM KURYE, 4925 SAYILI KARAYOLU TAŞIMA KANUNU’NA İSTİNADEN BİR TAŞIMACILIK FAALİYETİ YÜRÜTMEMEKTEDİR.<br/>
5. TAŞIRIM KURYE, KURYE ŞİRKETLERİ VE KURYELER TARAFINDAN VERİLEN HİZMET İLE İLGİLİ TAŞIRIM KURYE’NİN HİÇBİR SORUMLULUĞU YOKTUR. KURYELE VERİLEN HİZMET İLE İLGİLİ HİÇBİR GARANTİ VEREMEZ.<br/>

HİZMETİN TEMEL NİTELİKLERİ ve FİYATI<br/>
MADDE 4<br/>
Hizmet Türü: Müşterinin tasirimkurye.com üzerinden yaptığı seçimler.<br/>
Satış Bedeli (KDV dahil toplam): Müşterinin yaptığı seçimler sonrası tasirimkurye.com üzerinde gösterilen fiyattır.<br/>

MALIN TESLİMİ, ŞEKLİ, MASRAFLARI<br/>
MADDE 5<br/>
1. KULLANICI, Platform üzerinden teslimat hizmeti talep ettiğinde, kendisine platforma kayıtlı kurye hizmeti veren şahıslar ekrana gelecektir. KULLANICI ile KURYELER arasındaki hukuksal ilişki, KULLANICI’NIN KURYE’DEN hizmet talep ettiği an olan gönderiyi tamamla Kabul tuşuna basması ile başlar.<br/>
2. KULLANICI, KURYE çağrı yapılan adrese ulaştığında gönderiyi hazır bulundurmayı kabul ve taahhüt eder.<br/>
3. KULLANICI, ödemeyi Platform üzerindeki elektronik ödeme sistemleri aracılığı ile veya kuryenin teslim alım veya teslim edim anında nakit olarak yapacaktır.<br/>
4. KULLANICI, bir kereye mahsus olmak üzere kredi kartı bilgilerini Platform üzerinden sisteme girecektir. Kullanıcının güvenli olarak saklanan kredi kartı bilgileri, daha sonra tekrar bilgi girmeyi gerektirmeksizin kullanılacaktır.<br/>
5. KULLANICI’nın gelen kuryeden hizmet almaması durumunda dahi kendisinden 10 TL tutarında bir bedelin cezai şart olarak kesileceğini, oluşan masraf sebebiyle kendisinden kesilen haklı cezai şartın tahsil edilmesi sebebiyle itirazda bulunamayacağını bildiğini kabul ve taahhüt etmektedir.<br/>
6. TAŞIRIM KURYE, KULLANICI’nın her talebinde platforma kayıtlı KURYE ŞİRKETLERİ veya KURYELERİN kendisine yanıt vereceğini taahhüt edemez. Ancak TAŞIRIM KURYE, kaydı alan Kuryelerin olumlu yanıt vermesi için gereken girişimleri yapar.<br/>
7. Süre, bedel ve benzeri temel bilgiler KURYE ŞİRKETLERİ tarafından bildirilmiş olup doğruluğu hususunda platformun yapısı gereği TAŞIRIM KURYE’NİN herhangi bir yükümlüğü yoktur.<br/>
8. Kuryeye verilen hedef teslimat adresinin tam ve eksiksiz olması KULLANICI’nın sorumluluğundadır. Gidilen adreste teslimatın kabul edilmemesi, adresin hatalı olması ve benzeri sebeplerle teslimatın gerçekleştirilememesi durumunda KULLANICI ile irtibata geçilecek ve gönderinin iadesi yapılacaktır.<br/>
9. İade durumlarında KULLANICI geri getirme sebebiyle, teslimat ödemesi kadar geri getirme ödemesini yapacağını kabul ve taahhüt eder.<br/>

TARAFLARIN HAK VE YÜKÜMLÜLÜKLERİ<br/>
MADDE 6<br/>
1. KULLANICI, üyelik prosedürünü yerine getirirken, web sitesi ve uygulamadan faydalanırken ve uygulamadaki veya sitedeki hizmetlerle ilgili herhangi bir işlemi yerine getirirken, Sözleşme’de yer alan tüm şartlara, uygulama ve sitenin ilgili yerlerinde belirtilen kurallara ve yürürlükteki tüm mevzuata uygun hareket edeceğini, yukarıda belirtilen tüm şart ve kuralları anladığını ve onayladığını kabul, beyan ve taahhüt eder.<br/>
2. KULLANICI, platform üzerinden nakit para, çek, senet, altın vb. değerli madde ve taşlar, döviz gibi kıymetli evrak ve maddeler ile taşınması yasaklanmış olan hiçbir gönderiyi taşıtmayacağını ve bu sayılanlarla sınırlı olmamak üzere taşınması yasaklanmış ürün ve eşyaların taşınması sebebiyle doğacak her türlü hukuki ve cezai sorumluluk ve dolaylı ve doğrudan bütün zararın tazmin yükümlülüğünün kendisine ait olduğunu kabul, beyan ve taahhüt eder.<br/>
3. KULLANICI, Platform aracılığı ile işlem yaptığı Kuryeler hakkındaki şikayetlerini platform üzerinden destek@tasirimkurye.com’a bildirebilir. TAŞIRIM KURYE doğrudan, platform üzerindeki kayıtlı şirketlerin kuryelerinin davranışlarından herhangi bir şekilde sorumlu tutulamaz.<br/>
4. Hat kesilmesi, internet bağlantısının zayıf olması, konum bilgilerinin alınamaması gibi bunlarla sınırlı olmaksızın teknik sorunlar sebebiyle uygulamaya erişimde yaşanan kesintilerden dolayı KULLANICININ yaşayacağı sorunlardan TAŞIRIM KURYE sorumlu tutulamaz.<br/>

CAYMA HAKKI<br/>
MADDE 7<br/>
KULLANICI, kurye çağrı anından itibaren 3 dakika içerisinde çağrıyı ücretsiz iptal edebilir. 3 dakikayı geçen iptallerde KULLANICI’nın onayı ile hizmet ifa edilmeye başladığı için cayma bildirimleri kabul edilmeyecektir. KULLANICI bu durumda kendisinden 30 TL tutarında bir bedelin cezai şart olarak kesileceğini, oluşan masraf sebebiyle kendisinden kesilen haklı cezai şartın tahsil edilmesi sebebiyle itirazda bulunamayacağını bildiğini kabul ve taahhüt etmektedir.<br/>

KİŞİSEL VERİLERİN KORUNMASI<br/>
MADDE 8<br/>
KULLANICI, TAŞIRIM KURYE tarafından isim, adres, cep telefonu numarası, e-posta adresi, vatandaşlık numarası, kullanılan mobil aygıtın işletim sistemi versiyonu bilgisi, konum bilgileri, teslimat sonunda kuryeye verilen oylama bilgisinin ilgili faaliyeti gerçekleştirmek için meşru bir hak olarak işlenebileceğini kabul eder.<br/>

DİĞER HÜKÜMLER<br/>
MADDE 9<br/>
1. Şikayet: İş bu sözleşme konusu mal ve hizmetlere ilişkin şikâyet ve itirazlar konusunda yapılacak başvurular, Bakanlıkça her yıl Aralık ayında belirlenen parasal sınırlar dâhilinde, İstanbul İl Hakem Heyetine veya İstanbul Tüketici Mahkemesine yapılabilecektir.<br/>

<br/><br/><br/>

            </p>
      
          </div>
        </div>
      <Footer/>
    </div>
  );
}
