import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from '../styles/staticPages.js';
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { colors } from "components/Theme/WebTheme.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);


export default function TermCondition(props) {
    const { t,i18n } = useTranslation();
    const isRTL = i18n.dir();
  const classes = useStyles();
  const settings = useSelector(state => state.settingsdata.settings);
  const { ...rest } = props;
  return (
    <div style={{margin:'-8px'}}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/banner.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
 
      <div className={classes.container}>
            <br/>
            <h2  style={{textAlign:isRTL === 'rtl'?'right':'left',position: "relative",marginTop: "30px",minHeight: "32px",color:colors.Header,textDecoration: "none",[isRTL === "rtl" ? "marginRight" : "marginRight"]: isRTL ? "30px" : "0px",wordBreak: "break-word"}}>Veri Politikası</h2>
            {/* alt alta yazılmış hali */}
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} >

<br/>
6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU HAKKINDA AYDINLATMA METNİ VE GİZLİLİK POLİTİKASI<br/>
BÖLÜM I) 6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU HAKKINDA AYDINLATMA METNİ VE GİZLİLİK POLİTİKASI<br/>
1)Veri Sorumlusu<br/>
1.1)6698 sayılı Kişisel Verilerin Korunması Kanunu (KVKK) uyarınca, kişisel verileriniz; veri sorumlusu olan Taşırımkurye bünyesinde yer alan küçükbakkalköy Mah. Kocasinan Cad. selvili sokak 41/48 Ataşehir/İstanbul adresinde mukim Taşırım kurye lojistik ve turizm hizmetleri (Site Sahibi/Şirket) tarafından aşağıda açıklanan kapsamda işlenebilecektir.<br/>
1.2)İşbu Gizlilik Politikası ve KVKK Aydınlatma Metninde, Taşırım kurye lojistik ve turizm hizmetleri tarafından işletilmekte olan www.taşırımkurye.com internet sitesinin ve platformun Mobil uygulamasının işletilmesi esnasında Site/Mobil uygulama kullanıcıları/üyeleri/ziyaretçileri (“Veri Sahibi”) tarafından şirket ile paylaşılan veya Veri Sahibi’nin Site’nin kullanımı sırasında ortaya çıkan kişisel verilerin kullanımına ilişkin koşul ve şartlara yer verilmiştir.<br/>
2)Kişisel Veriler Hangi Amaçla İşlenmektedir?<br/>
2.1)Toplanan kişisel verileriniz TAŞIRIMKURYE ve TAŞIRIMKURYE ile iş ilişkisi içerisinde olan kişilerin hukuki ve ticari güvenliğinin sağlanması, ticari ve iş stratejilerinin belirlenmesi ve uygulanması amaçlarıyla KVKK’nın 5 ve 6’ncı maddelerinde belirtilen kişisel veri işleme şartları ve amaçları dâhilinde işlenebilecektir. Toplanan kişisel verileriniz; iş ortaklarımıza, hissedarlarımıza, kanunen yetkili kamu kurumlarına ve özel kişilere KVKK’nın 8 ve 9’uncu maddelerinde belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde aktarılabilecektir.<br/>
2.2)Kişisel verileriniz, yukarıda belirtilen amaçlar dışında kullanılmayacak olup, yasal yükümlülükler ve resmi kurum/kuruluşlar haricinde üçüncü kişilerle paylaşılmayacak ya da üçüncü kişilere aktarılmayacaktır. Kişisel verileriniz ancak 7 Nisan 2016 tarihli ve 29677 sayılı Resmi Gazete’de yayımlanarak yürürlüğe giren 6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında belirtilen kişisel veri işleme şartları ve amaçları kapsamında işlenebilmekte ve aktarılabilmektedir.<br/>
3)Kişisel Veri nedir?<br/>
3.1)Taşırımkurye'nin işlediği kişisel verileriniz aşağıda belirtilen şekilde Kişisel Verilerin Korunması Kanunu’na belirlenen esaslar doğrultusunda yazılmış olup. “kişisel veri” ifadesi bunlarla sınırlı olmamakla birlikte aşağıda yer alan bilgileri kapsamaktadır; Kimlik ve İletişim Bilgisi: ad, soyad, telefon, adres, işyeri bilgisi, e-posta adresi , Kullanıcı Bilgisi, Kullanıcı İşlem Bilgisi ve Finansal Bilgi: üyelik bilgileri, üyelik ID numarası, Site Sahibinin hizmetlerinden yararlandığınız tarih ve saate ilişkin veriler, Site Sahibi veya Yardım ile iletişime geçme nedenleriniz, Site üzerinde arama yaparken kullandığınız terimler , yorumlarınız, kullanım sırasında oluşan hatalar, fatura ve ödeme bilgisi, bakiye bilgisi (kullanıcıya gönderilen faturalar ve kullanıcılardan alınan ödemelere ait dekont örnekleri, fatura numarası, fatura tutarı, fatura kesim tarihi gibi veriler.)Lokasyon Bilgisi, İşlem Güvenliği Bilgisi: log in credential bilgileri, parola bilgileri,uygulamayı kullanma sıklığınıza ilişkin raporlar ve değerlendirmeler, hedefleme bilgileri, çerez (cookie) kayıtları vb. bilgiler,Talep/Şikayet Yönetimi Bilgisi, Site üzerinden yaptığınız talep ve şikayetler, Risk Yönetimi Bilgisi: IP adresi. Kurye uygulamamız, uygulama arka planda çalışmakta olsa bile mevcut konumunuz hakkında bilgi toplar. Bu bilgileri, söz konusu teslimat için atanmışken teslimatı sipariş eden müşteriyle paylaşabiliriz. Mevcut konum bilginiz ilgili kanuna uygun olarak ihtiyaç olan en kısa süre için tutulacaktır.<br/>
3.2)Kanunun 3. ve 7. maddeleri çerçevesinde anonim hale getirilen veriler, anılan kanun hükümleri uyarınca kişisel veri olarak kabul edilmeyecek ve bu verilere ilişkin işleme faaliyetleri işbu Gizlilik Politikası hükümleri ile bağlı olmaksızın gerçekleştirecektir. (Verilerinizin işlenip işlenmediği konusunda başvuru konusunda bkz : BÖLÜM II )<br/>
4)Verilerinizin Kullanım Amacı Nedir?<br/>
4.1)Site Sahibi TAŞIRIMKURYE ile paylaştığınız kişisel verileriniz, Site Sahibi ve Site üzerinden veya Mobil uygulama üzerinden sağlanan hizmetlerden faydalanabilmeniz için, Site’ye üyelik kaydınızın gerçekleştirilmesi, üyelik kaydına ilişkin güncellemelerin yapılması, Site Sahibinin sunduğu ve Site üzerinden sunulan hizmetlerin iyileştirilmesi yeni hizmetlerin tanıtılması ve bu doğrultuda tarafınıza gerekli bilgilendirmelerin yapılması dahil ticari faaliyetlerinin yürütülmesi, ticari ve iş stratejilerinin belirlenmesi ve uygulanması, Site Sahibi ve Site Sahibi ile iş ilişkisi içinde olan kişilerin hukuki ve ticari güvenliğinin temininin sağlanması ve bu kapsamda tarafınıza gerekli bilgilendirilmelerin yapılması ve bu faaliyetlerin doğasından kaynaklanan yükümlülüklerin yerine getirilmesi için işlenebilir.<br/>
4.2)Söz konusu kişisel bilgiler öncelikle sizinle iletişime geçmek amacıyla toplanmaktadır. Mobil uygulama üzerindeki deneyiminizi iyileştirmek, iletişim yönetimi sürecine ilişkin değerlendirme yapılması, memnuniyet araştırmalarının yapılması gibi müşteri memnuniyeti temelli kullanmak üzere verileriniz işlendiği gibi işlenen bu veriler şirket içi raporlama ve iş geliştirme faaliyetleri kapsamında da kullanılabilecektir. Ayrıca kimliğiniz ifşa edilmeksizin anonim biçimde çeşitli istatistiksel değerlendirmeler yapma, veri tabanı oluşturma ve pazar araştırmalarında bulunma amacıyla da kullanılabilecektir.<br/>
4.3) Kanunu’nun 5 ve 8. maddeleri uyarınca ve/veya ilgili mevzuattaki istisnaların varlığı halinde kişisel verileri Veri Sahiplerinin ayrıca rızası gerekmeksizin Site Sahibi işleyebilecek ve üçüncü kişilerle paylaşabilecektir. Bu durumların en temel olanları aşağıda belirtilmiştir: Kanunlarda açıkça öngörülmesi, Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün korunması için zorunlu olması, Veri Sahibi ile Site Sahibi arasında herhangi bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, kişisel verilerin işlenmesinin gerekli olması, Site Sahibinin hukuki yükümlülüklerini yerine getirebilmesi için zorunlu olması, Veri Sahibi’nin kendisi tarafından alenileştirilmiş olması, Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması, Veri Sahibi’nin temel hak ve özgürlüklerine zarar vermemek kaydıyla Site Sahibinin meşru menfaatleri için veri işlenmesinin zorunlu olması.<br/>
4.4)Veri toplama işlemi için Site Sahibi çerez (Cookie) kullanabilecek ve bu kapsamda veri işleyerek üçüncü kişiler tarafından sunulan analiz hizmetleri kapsamında işlenmesi amacıyla sadece bu analiz hizmetlerinin gerektirdiği ölçüde kullanılması amacıyla üçüncü kişilere iletebilecektir. (Bkz: BÖLÜM III Çerez Politikası)<br/>
5)İşlenen Veriler Kimlere hangi Amaç ile Aktarılabilecektir?<br/>
5.1) Toplanan kişisel verileriniz, kullanıcı deneyiminizin geliştirilmesi (iyileştirme ve kişiselleştirme dâhil), güvenliğinizin temin edilmesi, hileli ya da izinsiz kullanımların tespit edilmesi, saha araştırması,

<br/><br/><br/>

            </p>
      
          </div>
        </div>
      <Footer/>
    </div>
  );
}
