import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from '../styles/staticPages.js';
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { colors } from "components/Theme/WebTheme.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);


export default function PrivacyPolicy(props) {
    const { t,i18n } = useTranslation();
    const isRTL = i18n.dir();
  const classes = useStyles();
  const settings = useSelector(state => state.settingsdata.settings);
  const { ...rest } = props;
  return (
    <div style={{margin:'-8px'}}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/banner.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
 
      <div className={classes.container}>
            <br/>
            <h2  style={{textAlign:isRTL === 'rtl'?'right':'left',position: "relative",marginTop: "30px",minHeight: "32px",color: colors.Header,textDecoration: "none",[isRTL === "rtl" ? "marginRight" : "marginRight"]: isRTL ? "30px" : "0px",wordBreak: "break-word"}}>{t('privacy_policy')}</h2>
            <p className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} >
            Gizlilik Politikası
Son Güncelleme: [20.08.2024]<br/><br/>
1. Giriş<br/>
[Taşırım Kurye] (bundan sonra "Biz," "Bize," veya "Bizim" olarak anılacaktır) olarak kullanıcılarımızın gizliliğine büyük önem veriyoruz. Bu Gizlilik Politikası, uygulamamız aracılığıyla topladığımız kişisel verilerin nasıl toplandığını, kullanıldığını, paylaşıldığını ve korunduğunu açıklar.<br/><br/>
2. Topladığımız Bilgiler<br/>
Uygulamamız aracılığıyla aşağıdaki türde kişisel verileri toplayabiliriz:<br/>
Kişisel Bilgiler: Ad, soyad, telefon numarası, e-posta adresi, adres bilgileri.<br/>
Konum Bilgileri: Teslimat işlemleri sırasında konum verileri.<br/>
Ödeme Bilgileri: Kredi kartı bilgileri ve diğer ödeme detayları (ödeme işleme hizmet sağlayıcıları tarafından işlenir ve saklanır).<br/>
Kullanım Bilgileri: Uygulama kullanımıyla ilgili veriler, cihaz bilgileri, IP adresi, tarayıcı türü.<br/>
İletişim Bilgileri: Müşteri destek talepleri, geri bildirimler ve şikayetler.<br/><br/>
3. Bilgi Kullanımı<br/>
Topladığımız kişisel bilgileri aşağıdaki amaçlar için kullanabiliriz:<br/>
Hizmet Sağlama: Teslimat taleplerinizi işleme, teslimat sürecini yönetme.<br/>
İletişim: Sipariş durumu, güncellemeler veya müşteri desteği hakkında bilgilendirme.<br/>
Ödeme İşlemleri: Ödemelerinizi işleme ve güvenliğini sağlama.<br/>
Geliştirme: Uygulamayı ve hizmetlerimizi geliştirme, kullanıcı deneyimini iyileştirme.<br/>
Pazarlama: İzin vermişseniz size promosyonlar ve teklifler gönderme.<br/><br/>
4. Bilgi Paylaşımı<br/>
Kişisel bilgilerinizi şu durumlarda paylaşabiliriz:<br/>
Hizmet Sağlayıcıları: Teslimat ve ödeme işlemleri gibi hizmetler için üçüncü taraf hizmet sağlayıcılarla.<br/>
Yasal Yükümlülükler: Yasal süreçler, mahkeme kararları veya devlet yetkilileri tarafından talep edildiğinde.<br/>
Güvenlik: Kötüye kullanım, dolandırıcılık veya diğer yasa dışı faaliyetleri önlemek amacıyla.<br/><br/>
5. Veri Güvenliği<br/>
Kişisel verilerinizin güvenliğini sağlamak için uygun teknik ve organizasyonel önlemleri alıyoruz. Ancak, internet üzerinden veri iletiminde her zaman riskler bulunduğunu unutmayın.<br/><br/>
6. Haklarınız<br/>
Kişisel verilerinizle ilgili olarak aşağıdaki haklara sahipsiniz:<br/>
Erişim: Kişisel verilerinize erişim talep edebilirsiniz.<br/>
Düzeltme: Yanlış veya eksik kişisel verilerinizi düzeltebilirsiniz.<br/>
Silme: Kişisel verilerinizin silinmesini talep edebilirsiniz.<br/>
İtiraz: Verilerinizin işlenmesine itiraz edebilirsiniz.<br/>
Taşınabilirlik: Verilerinizi başka bir hizmet sağlayıcıya taşıma hakkına sahipsiniz.<br/><br/>
7. Çocukların Gizliliği<br/>
Uygulamamız, 13 yaşının altındaki çocuklar için tasarlanmamıştır ve bu yaş grubundaki çocuklardan bilerek kişisel bilgi toplamayız. Eğer bir çocuğun kişisel verilerini topladığımızı öğrenirsek, bu verileri derhal sileriz.<br/><br/>
8. Gizlilik Politikasında Değişiklikler<br/>
Gizlilik politikamız zaman zaman güncellenebilir. Herhangi bir değişiklik yapıldığında, güncellenmiş politika uygulama içinde veya diğer uygun kanallarla kullanıcılarımıza bildirilir.<br/><br/>
9. İletişim<br/>
Gizlilik politikamız veya kişisel verilerinizle ilgili herhangi bir soru veya endişeniz varsa, lütfen bizimle şu iletişim bilgileri üzerinden iletişime geçin:<br/>
E-posta: destek@tasirimkurye.com Telefon: 0850 474 4 836 Adres: Karlıktepe Mah.Soğanlık Cad.No:52 – Kartal - İstanbul<br/>

            </p>
            
          <br/>
          <br/>
        </div>
        </div>
      <Footer/>
    </div>
  );
}
