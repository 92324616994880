/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { List, ListItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styles from "../../styles/footerStyle.js";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { colors } from "../../components/Theme/WebTheme";
import { FONT_FAMILY } from "../../common/sharedFunctions.js";

import BANNER2 from "../../assets/img/banner2.jpg";
const useStyles = makeStyles(styles);

export default function HomeFooter(props) {
  const classes = useStyles();
  const settings = useSelector((state) => state.settingsdata.settings);
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();

  return (
    <>
      <div style={{ width: "80%", margin: "auto",borderRadius: "10px",overflow: "hidden",marginTop: "20px" }}>
        <img src={BANNER2} alt="banner" style={{ width: "100%", height: "auto" }} />
      </div>
      <footer
        className={footerClasses}
        style={{ backgroundColor: colors.HEADER }}
      >
        <div
          className={classes.container}
          style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
        >
          <div
            className={isRTL === "rtl" ? classes.right : classes.left}
            style={{ marginBottom: "12px" }}
          >
             <List className={classes.list} style={{padding: '0px'}}>
            <ListItem className={classes.inlineBlock}>
              <a
                style={{cursor:'pointer', fontFamily: FONT_FAMILY,color:"white"}}
                className={classes.block}
                onClick={(e) => { e.preventDefault(); navigate('/') }}
              >
                {t('home')}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                className={classes.block}
                style={{cursor:'pointer', fontFamily: FONT_FAMILY,color:"white"}}
                onClick={(e) => { e.preventDefault(); navigate('/bookings') }}
              >
                {t('myaccount')}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                className={classes.block}
                style={{cursor:'pointer', fontFamily: FONT_FAMILY,color:"white"}}
                onClick={(e) => { e.preventDefault(); navigate('/about-us') }}
              >
                {t('about_us')}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                className={classes.block}
                style={{cursor:'pointer', fontFamily: FONT_FAMILY,color:"white"}}
                onClick={(e) => { e.preventDefault(); navigate('/contact-us') }}
              >
                {t('contact_us')}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                style={{cursor:'pointer', fontFamily: FONT_FAMILY,color:"white"}}  
                className={classes.block}
                onClick={(e) => { e.preventDefault(); navigate('/privacy-policy') }}
              >
                {t('privacy_policy')}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                style={{cursor:'pointer', fontFamily: FONT_FAMILY,color:"white"}}  
                className={classes.block}
                onClick={(e) => { e.preventDefault(); navigate('/term-condition') }}
              >
                {t('term_condition')}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                style={{cursor:'pointer', fontFamily: FONT_FAMILY,color:"white"}}  
                className={classes.block}
                onClick={(e) => { e.preventDefault(); navigate('/veri-guvenligi') }}
              >
                Veri Güvenliği
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                style={{cursor:'pointer', fontFamily: FONT_FAMILY,color:"white"}}  
                className={classes.block}
                onClick={(e) => { e.preventDefault(); navigate('/satis-ve-hizmet') }}
              >
               Satış ve Hizmet Politikası 
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                style={{cursor:'pointer', fontFamily: FONT_FAMILY,color:"white"}}  
                className={classes.block}
                onClick={(e) => { e.preventDefault(); navigate('/kvkk-aydinlatma') }}
              >
                KVKK Aydınlatma Metni
              </a>
            </ListItem>
          </List>
        </div>
        <br/><br/><br/>
          {settings && settings.CompanyWebsite ? (
            <div style={{ marginTop: "12px", color: colors.Header_Background }}>
              &copy; {1900 + new Date().getYear() + " "}
              <a
                style={{
                  color: colors.Header_Background,
                  fontFamily: FONT_FAMILY,color:"white"
                }}
                href={settings.CompanyWebsite}
                className={aClasses}
                target="_blank"
              >
                {settings.CompanyName}
              </a>
            </div>
          ) : null}
        </div>
      </footer>
    </>
  );
}

HomeFooter.propTypes = {
  whiteFont: PropTypes.bool,
};
