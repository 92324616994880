import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from '../styles/staticPages.js';
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import { colors } from "components/Theme/WebTheme.js";


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function AboutUs(props) {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div style={{margin:'-8px'}}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "primary"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/banner.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
 
        <div className={classes.container}>
            <br/>
            <h2 style={{textAlign:isRTL === 'rtl'?'right':'left',position: "relative",marginTop: "30px",minHeight: "32px",color:colors.Header,textDecoration: "none",[isRTL === "rtl" ? "marginRight" : "marginRight"]: isRTL ? "30px" : "0px",wordBreak: "break-word"}}>{t('about_us')}</h2>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} >
            <b>Hız ve Güvenin Adı: Taşırım Kurye</b><br/>
Günümüz iş dünyasında, hızlı ve güvenilir teslimat hizmetleri, müşteri memnuniyetinin temel taşlarından biridir. Taşırım Kurye bu ihtiyaca yanıt veren ve sektördeki en güvenilir isimlerden biri olarak öne çıkmaktadır. Taşırım Kurye, müşteri odaklı yaklaşımı, deneyimli ekibi ve modern teknolojisi ile her türlü kurye ihtiyacıza en iyi şekilde hizmet sunar.<br/><br/>

<b>Müşteri Odaklı Hizmet Anlayışı</b><br/>
Taşırım Kurye müşteri memnuniyetini her zaman ön planda tutar. Kurye hizmetlerimiz, özel teslimat ihtiyaçlarınıza göre esneklik sağlar ve size en uygun çözümleri sunar. İster küçük bir paket, ister büyük bir yük olsun, her teslimatı aynı titizlikle ve özenle gerçekleştiririz.<br/><br/>

<b>Yenilikçi Teknoloji ve İzleme Sistemleri</b><br/>
Teslimat sürecinde şeffaflık ve güvenilirlik, Taşırım Kurye'nin öncelikleri arasında yer alır. Gelişmiş izleme sistemlerimiz sayesinde, paketlerinizin anlık durumunu kolayca takip edebilirsiniz. Modern teknoloji kullanarak, her teslimatın doğru zamanda ve eksiksiz bir şekilde yerine ulaştığından emin oluruz.<br/><br/>

<b>Deneyimli ve Profesyonel Kadro</b><br/>
Ekibimiz, sektördeki geniş deneyimi ve profesyonel yaklaşımı ile tanınır. Kurye personelimiz, her türlü teslimat ve lojistik ihtiyacında yüksek bir hizmet standardı sunar. Eğitimli ve güvenilir çalışanlarımız, paketlerinizin güvenli bir şekilde teslim edilmesi için özenle çalışır.<br/><br/>

<b>Çeşitli Hizmet Seçenekleri</b><br/>
Taşırım Kurye, çeşitli teslimat seçenekleri sunarak, tüm ihtiyaçlarınıza uygun çözümler üretir. Standart teslimat, ekspres servis, şehirler arası gönderiler ve daha fazlasını kapsayan geniş hizmet yelpazemiz ile her türlü kurye talebinizi karşılarız.<br/><br/>

<b>Siz Değerli Müşterilerimiz İçin</b><br/>
Taşırım Kurye olarak, size en iyi hizmeti sunmayı hedefliyoruz. Paketlerinizin güvenli ve zamanında teslim edilmesi için sürekli olarak kendimizi geliştiriyoruz. Müşteri geri bildirimleri bizim için çok kıymetlidir ve bu geri bildirimleri dikkate alarak hizmet kalitemizi artırıyoruz.<br/><br/>

<b>İletişim</b><br/>
Detaylı bilgi almak, hizmetlerimiz hakkında daha fazla öğrenmek veya bize ulaşmak için destek@tasirimkurye.com veya 0850 474 48 36 nolu numaramız üzerinden bizimle iletişime geçebilirsiniz. Taşırım Kurye olarak, her türlü kurye ihtiyacınızda yanınızdayız!<br/>
            </p>
            <br/>
        </div>
        </div>

      <Footer />
    </div>
  );
}
