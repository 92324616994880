import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from '../styles/staticPages.js';
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { colors } from "components/Theme/WebTheme.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);


export default function TermCondition(props) {
    const { t,i18n } = useTranslation();
    const isRTL = i18n.dir();
  const classes = useStyles();
  const settings = useSelector(state => state.settingsdata.settings);
  const { ...rest } = props;
  return (
    <div style={{margin:'-8px'}}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/banner.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
 
      <div className={classes.container}>
            <br/>
            <h2  style={{textAlign:isRTL === 'rtl'?'right':'left',position: "relative",marginTop: "30px",minHeight: "32px",color:colors.Header,textDecoration: "none",[isRTL === "rtl" ? "marginRight" : "marginRight"]: isRTL ? "30px" : "0px",wordBreak: "break-word"}}>{t('term_condition')}</h2>
            
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{whiteSpace: "pre-line"}}>
            Kullanım Şartları
Son Güncelleme: [20.08.2024]<br/>
1. Giriş<br/>
Bu Kullanım Şartları ("Şartlar"), TAŞIRIM KURYE ("Uygulama") adlı mobil uygulamanın ve/veya web sitesinin kullanımını düzenler. Uygulamayı kullanarak bu Şartları kabul etmiş olursunuz. Şartlara uymayı kabul etmiyorsanız, uygulamayı kullanmamalısınız.<br/><br/>
2. Hizmet Tanımı<br/>
Uygulama, kullanıcılara kargo ve kurye hizmetleri sunar. Bu hizmetler, paket gönderimi, teslimat takibi, kurye talebi ve benzeri işlemleri içerir.<br/><br/>
3. Kullanıcı Hesabı<br/>
Hesap Oluşumu: Uygulamayı kullanmak için bir kullanıcı hesabı oluşturmanız gerekebilir. Hesap bilgilerinizi doğru ve güncel tutmakla yükümlüsünüz.<br/>
Hesap Güvenliği: Hesabınızın güvenliğini sağlamak sizin sorumluluğunuzdadır. Hesabınızdan yetkisiz kullanımlardan biz sorumlu değiliz.<br/><br/>
4. Hizmet Kullanımı<br/>
Kullanım Amacı: Uygulamayı yalnızca yasal amaçlarla, kurye hizmetleri almak ve göndermek için kullanabilirsiniz.<br/>
Kullanıcı Yükümlülükleri: Sağladığınız tüm bilgilerin doğru, eksiksiz ve güncel olduğunu beyan edersiniz. Yanlış veya eksik bilgi vermeniz durumunda sorumluluk tamamen size aittir.<br/><br/>
5. Ödeme ve Ücretler<br/>
Ödemeler: Kargo hizmetleri için belirlenen ücretler, uygulama içinde belirtilen şekilde ödenmelidir. Ödemelerde gecikme veya başarısızlık durumunda, hizmet sağlanmayabilir veya askıya alınabilir.<br/>
Ücret Değişiklikleri: Hizmet ücretleri zaman zaman değişebilir. Ücretlerdeki değişiklikler uygulama içinde veya diğer uygun kanallarla size bildirilecektir.<br/><br/>
6. Teslimat <br/>
Teslimat Süreleri: Teslimat süreleri tahminidir ve gecikmeler olabilir. Teslimat süresi ile ilgili herhangi bir garanti verilmez.<br/><br/>
7. Kullanıcı İçerikleri<br/>
İçerik Paylaşımı: Uygulama üzerinden sağladığınız içerikler (paket bilgileri, iletişim detayları vb.) yalnızca hizmetlerin sağlanması amacıyla kullanılacaktır.<br/>
İçerik Sorumluluğu: Gönderdiğiniz içeriklerin doğru, yasal ve üçüncü taraf haklarına aykırı olmadığını garanti edersiniz. Yanlış veya yasa dışı içerikler nedeniyle oluşabilecek sorunlardan siz sorumlusunuz.<br/><br/>
8. Uygulama İçeriği ve Fikri Mülkiyet<br/>
Telif Hakları: Uygulama içeriği, grafikler, markalar ve diğer fikri mülkiyet unsurları Taşırım Kurye’ye veya lisans verenlerine aittir. Uygulama içeriğini izinsiz olarak kullanmanız yasaktır.<br/>
Lisans: Uygulamanın kullanımı için size kişisel, sınırlı, geri alınabilir ve devredilemez bir lisans verilir. Bu lisans, uygulamayı yalnızca kişisel, ticari olmayan amaçlarla kullanmanızı sağlar.<br/><br/>
9. Gizlilik<br/>
Kişisel verilerinizin nasıl toplandığı, kullanıldığı ve korunduğuna ilişkin bilgiler için [Gizlilik Politikası] kısmını inceleyebilirsiniz.<br/><br/>
10. Sorumluluk Reddi<br/>
Uygulama, kesintisiz veya hatasız çalışacağını garanti etmez. Uygulamanın kullanımı sonucunda oluşabilecek doğrudan veya dolaylı zararlar için sorumluluk kabul edilmez.<br/><br/>
11. Değişiklikler<br/>
Bu Şartlar zaman zaman güncellenebilir. Güncellenmiş Şartlar uygulama içinde veya diğer uygun kanallarla size bildirilecektir. Değişiklikleri takip etmek sizin sorumluluğunuzdadır.<br/><br/>
12. Yasal Çerçeve<br/>
Bu Şartlar, Türkiye/İstanbul yasalarına tabidir ve herhangi bir uyuşmazlık durumunda İstanbul Anadolu mahkemeleri yetkilidir.<br/><br/>
13. İletişim<br/>
Bu Şartlar hakkında sorularınız varsa, bizimle şu iletişim bilgileri aracılığıyla iletişime geçebilirsiniz:<br/>
E-posta: destek@tasirimkurye.com Telefon: 0850 474 4 836 Adres: Karlıktepe mah.Soğanlık Cad.No.52<br/>
<br/><br/><br/>

            </p>
      
          </div>
        </div>
      <Footer/>
    </div>
  );
}
