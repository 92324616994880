export const FirebaseConfig = {
	"projectId": "tasirim-kurye-fad66",
	"appId": "1:333351383864:web:550c73005723da59c707cd",
	"databaseURL": "https://tasirim-kurye-fad66-default-rtdb.firebaseio.com",
	"storageBucket": "tasirim-kurye-fad66.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDLveddG_5kT7OEPHcrUlJNfNOaLEHqi1Q",
	"authDomain": "tasirim-kurye-fad66.firebaseapp.com",
	"messagingSenderId": "333351383864",
	"measurementId": "G-SF7MSVV48C"
};